@import 'fonts/inter/inter';
@import '_custom_variables';
@import '_helpers';
@import '_reset';

html {
    overflow-y: auto;
  }
  * {
    font-family: $family-primary;
  }
  body {
    margin: 0;
    font-weight: $weight-normal;
  }
  .reCaptchaHolder {
    font-family: $family-primary;
  }
  .black {
    font-family: $family-primary;
    font-weight: 700;
    font-size: 2.5rem;
  }
  
  .navbar-element {
    color: $white;                   
    &:after {            
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: $white;
      opacity: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transform: translateY(0.5rem);
      -ms-transform: translateY(0.5rem);
      transform: translateY(0.5rem);
      border-radius: 2rem;
      width: 100%;
    }
    &:hover {
      background-color: transparent;
      &:after {
        opacity: 1;
        transform: translateY(0);           
      }
    }
  }
  .active {
    color: $indigo;
    background: transparent;
    &:after{
      content: "";  
      position: absolute;
      top: -2.8rem;
      left: 0;
      height: 5px;
      background-color: $indigo;
      opacity: 1;
      border-radius: 2rem;     
      width: 100%; 
    }
    &:hover {
      background-color: transparent;
      &:after {
        opacity: 1;
      }
    }
  }
  
  