.nowrap {
    white-space: nowrap;
  }
  .m-t-1 {
    margin-top: 1rem;
  }
  .m-t-2 {
    margin-top: 2rem;
  }
  .m-b-1 {
    margin-bottom: 1rem;
  }
  .m-l-1 {
    margin-left: 1rem;
  }
  .m-r-1 {
    margin-right: 1rem;
  }
  .m-l-half-rem {
    margin-left: 0.5rem;
  }
  .m-r-half-rem {
    margin-right: 0.5rem;
  }
  .marginless {
    margin: 0;
  }
  .w-100 {
    width: 100%;
  }
  .has-text-centered {
    text-align: center;
  }
  .has-text-right {
    text-align: right;
  }
  .has-text-weight-normal {
    font-weight: $weight-normal;
  }
  .has-text-weight-bold {
    font-weight: $weight-bold;
  }
  .is-relative {
    position: relative;
  }
  .is-flex {
    display: flex;
  }
  .flex-start {
    justify-content: flex-start !important;
  }
  .flex-end {
    justify-content: flex-end !important;
  }
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .justify-center {
    justify-content: center !important;
  }
  .flex-column {
    flex-direction: column;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .align-self-end {
    align-self: flex-end;
  }
  .align-self-center {
    align-self: center;
  }
  .row-wrap {
    flex-flow: row wrap;
  }
  .flex-justify-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-1 {
    flex: 1 0;
  }
  .has-gap-1 {
    gap: 1rem;
  }
  .has-gap-2 {
    gap: 2rem;
  }
  .has-gap-4 {
    gap: 4rem;
  }
  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  