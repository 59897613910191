h1, h2, h3 {
    margin: 0;
    font-weight: $weight-normal;
    font-size: 1rem;
  }
  p {
    @media screen and (min-width: $desktop) {
      font-size: 1.125rem;
    }
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: $black;
  }
  ul {
    list-style:none;
    padding: 0;
  }
  input, textarea, select {
    border-radius: 1.125rem;
    font-family: $family-primary;
    font-size: 1rem;
    width: 100%;
    padding: 0.25rem 0.75rem;
    box-sizing: border-box;
    border: 1px solid black;
    margin-bottom: 0.5rem;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 1.125rem;
    }
    &.is-danger {
      border-color: $danger;
    }
  }
  select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    option[value=""][disabled] {
      display: none;
    }
  }
  .select {
    position: relative;
    &:after {
      position: absolute;
      top: 50%;
      right: 1.125em;
      transform: rotate(-45deg) translateY(-50%);;
      transform-origin: center;
      height: 0.5em;
      width: 0.5em;
      border: 2px solid black;
      border-radius: 2px;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      margin-top: -0.4375em;
      pointer-events: none;
      z-index: 4;
    }
  }
  .button {
    background-color: $pink;
    color: $white;
    border: none;
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: $weight-bold;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: all 0.2s;
    height: fit-content;
    position: relative;
    &:hover {
      background-color: $pink;
      box-shadow: 0px 3px 5px #00000026;
    }
    &.is-loading {
      pointer-events: none;
      color: transparent !important;
      &:after {
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        animation: spinAround 0.5s linear infinite;
        border-radius: 290486px;
        border-color: transparent transparent white!important;
        border-style: solid;
        border-width: 2px;
        content: "";
        display: block;
        height: 1em;
        width: 1em;
      }
    }
  }
  .input-with-addon {
    display: flex;
    margin-bottom: 0.5rem;
    .addon {
      border-radius: 1.125rem 0 0 1.125rem;
      font-family: $family-primary;
      font-size: 1rem;
      width: fit-content;
      padding: 0.25rem 0.75rem;
      box-sizing: border-box;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      border-left: none;
      border-radius: 0 1.125rem 1.125rem 0;
      margin-bottom: 0;
    }
  
  }

  .label {
    font-size: 1.5rem;
  }