$weight-normal: 400;
$weight-bold: 700;
$gap: 32px !default;
$tablet: 768px !default;
$desktop: 960px + (2 * $gap) !default;
$widescreen: 1152px + (2 * $gap) !default;
$fullhd: 1344px + (2 * $gap) !default;

$mobileMaxW: 767px;
$tabletMaxW: $desktop - 1px;
$desktopMaxW: $widescreen - 1px;
$widescreenMaxW: $fullhd - 1px;

$white: #FFF;
$whiteOpacity: #FFFFFFBE;
$black: #161C2D;
$completeBlack: #000;

$greyLightest: #0000000D;
$greyLighter: #A8ACB7;
$grey: #6E727D;
$greyOpacity: #E1DFFF66;
$danger: #ff3860;

$turquoise: #32E7D2;
$pink: #FF2BAE5C;
$magenta: #FF2BAE;
$purple: #473BF05C;
$purpleLighter: #473BF019;
$indigo: #473BF0;
$aliceBlue: #F4F7FA;

$family-primary: $family-name, sans-serif;

:export {
    white: $white;
    whiteOpacity: $whiteOpacity;
    black: $black;
    completeBlack: $completeBlack;
    greyLightest: $greyLightest;
    greyLighter: $greyLighter;
    grey: $grey;
    greyOpacity: $greyOpacity;
    turquoise: $turquoise;
    pink: $pink;
    magenta: $magenta;
    purple: $purple;
    purpleLighter: $purpleLighter;
    indigo: $indigo;
    aliceBlue: $aliceBlue;
};