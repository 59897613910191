$font-path: '/fonts' !default;
$family-name: 'Inter';

@font-face {
  font-family: $family-name;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Inter Regular'),
  local('Inter Regular'),
  url('#{$font-path}/Inter-Regular.woff2') format('woff2'),
}
@font-face {
  font-family: $family-name;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Inter Bold'),
  local('Inter-Bold'),
  url('#{$font-path}/Inter-Bold.woff2') format('woff2'),
}
